* {
  box-sizing: border-box;
  margin: 0;
}

.ck.ck-editor__editable, .ck.ck-editor__editable > .ck-placeholder::before {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.ck.ck-editor {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #E5E2E1 !important;
  background-color: #FFFFFF !important;
}
.ck.ck-editor__main>.ck-editor__editable{
  padding: 16px;
  min-height: 138px;
  border: none;
}
.ck.ck-editor__editable_inline>:first-child {
  margin-top: 0 !important;
}
.ck.ck-editor__editable_inline>:last-child {
  margin-bottom: 0 !important;
}
.ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
  border: none !important;
  border-bottom : 1px solid #E5E2E1 !important;
}
.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  border: 1px solid #000 !important; 
  box-shadow: none !important;
  outline: none !important;
}
.ck.ck-sticky-panel__content {
  border-color: transparent;
}
.ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items {
  flex-wrap: nowrap;
  gap: 8px;
}
html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

.disabled-row {
  pointer-events: none;
  opacity: 0.5;
}